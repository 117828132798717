const URLS = Object.freeze({
	LOGIN: `/auth/login`,
	REGISTER: `/auth/register`,
	STORE_GOOGLE_ACCOUNT: `/user/auth-google`,
	ACTIVATION: `/send-activation`,
	RESET_PASSWORD: `user/reset-password`,
	RESEND_ACTIVATION: `user/resend-activation`,
	PRODUCT: `/products`,
	ORDER: `/orders`,
	SUPPLIER: `/suppliers`,
	CUSTOMER: `/customers`,
	CATEGORY: `/categories`,
	PURCHASE:`/purchase`,
	USER: '/users',
	EXPENSE: '/expenses'
})

export default URLS